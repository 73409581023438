import React, {useState} from 'react';
import RichText from '../RichText';

import * as S from './styled';

const AccordionItem = props => {
  const { content, title } = props;
  const [setActive, setActiveState] = useState("");


  function toggleAccordionItem() {
    setActiveState(setActive === "" ? "active" : "");
  }  
  
  return (
    <S.AccordionItem className={setActive} >

      <div className="header">
          <button onClick={toggleAccordionItem} role="button" tabIndex="0" />
          <h3 onClick={toggleAccordionItem} >{title}</h3>
          
      </div>
      <div className="content">
          <RichText dangerouslySetInnerHTML={{ __html: content }} />
      </div>

    </S.AccordionItem>
    );
};

export default AccordionItem;
