import React from 'react';
import PropTypes from 'prop-types';
import useTranslations from '../useTranslations';

import * as S from './styled';
import useResponsiveImage from '../useResponsiveImage';

const PageItem = ({
  lang,
  slug,
  title,
  description,
  image,
}) => {

  const {
    readMore
  } = useTranslations(lang);  


  return (
    <S.PageItemLink to={slug} lang={lang}>
      <S.PageItemWrapper>

        <S.PageItemHeading>
          <S.Circle>
          <S.PageItemImg
            fluid={useResponsiveImage(image)}
            alt={title}
          />
          </S.Circle>
          </S.PageItemHeading>

        <S.PageItemInfo>
          <S.PageItemTitle>{title}</S.PageItemTitle>
          <S.PageItemDescription>{description}</S.PageItemDescription>
          <S.ReadMore>{readMore} →</S.ReadMore>
          
        </S.PageItemInfo>
      </S.PageItemWrapper>
    </S.PageItemLink>
  );
};

PageItem.propTypes = {
  slug: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default PageItem;
