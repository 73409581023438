import styled from 'styled-components';
import media from 'styled-media-query';
import LocalizedLink from '../LocalizedLink';

export const BackLink = styled(LocalizedLink)`
  display: inline-block;
  font-weight: 600;
  text-decoration: none;
  margin-bottom: 2rem;
  ${media.greaterThan('medium')`
    margin-bottom: 3rem;
  `}    
  `;