import styled from 'styled-components';

export const Button = styled.button`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0 2.5rem;
  height: 44px;
  border-radius: 22px;
  background: var(--color-green);
  color: var(--color-white);
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: background-color .3s ease-in-out;
  &:hover{
    color: var(--color-white);
    background-color: var(--hover-color-green);
  }  
`;
