import styled from 'styled-components';
import media from 'styled-media-query';
import Img from 'gatsby-image';
import LocalizedLink from '../LocalizedLink';


export const PageItemLink = styled(LocalizedLink)`
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 10%), 0 0 0 1px rgb(10 10 10 / 2%);
  text-decoration: none;
  background-color: white;
  display: block;
  padding: var(--space-sm);
  border: 1px solid white;
  border-radius: 20px;
  &:hover{
    border-color: var(--border-light);
  }
  ${media.greaterThan('small')`
    padding: var(--space);
  `}    
  ${media.greaterThan('medium')`
    padding: var(--space-lg);
  `}  
`;

export const PageItemWrapper = styled.section`

`;

export const PageItemHeading = styled.div `
  background-color: var(--bg-light);
  padding: var(--space-sm);
  border-radius: 10px;
  ${media.greaterThan('small')`
    padding: var(--space);
  `}    
  ${media.greaterThan('medium')`
    padding: var(--space-lg);
  `}   
`

export const Circle = styled.div `
  box-shadow: 0 0.5em 1em -0.125em rgb(10 10 10 / 5%), 0 0 0 1px rgb(10 10 10 / 1%);
  overflow: hidden;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 0 auto;
  ${media.greaterThan('small')`
    width: 120px;
    height: 120px;
  `}    
  ${media.greaterThan('medium')`
    width: 140px;
    height: 140px;
  `}  
  ${media.greaterThan('large')`
  width: 180px;
  height: 180px;
`}  
`

export const PageItemImg = styled(Img)`
  width: 100%;
  height: 100%;
`;

export const PageItemInfo = styled.div`
  padding-top: var(--space-sm);
  ${media.greaterThan('small')`
    padding-top: var(--space);
  `}    
  ${media.greaterThan('medium')`
    padding-top: var(--space-lg);
  `}    
`;


export const PageItemTitle = styled.h3`
  font-weight: 600;
`;

export const PageItemDescription = styled.p`
  display:none;
  margin-top: var(--space-sm);
  color: var(--text-color);
  ${media.greaterThan('small')`
    display: block;
  `}    
`;

export const ReadMore = styled.div`
  ${media.greaterThan('small')`
    margin-top: var(--space-sm);
  `}   
`